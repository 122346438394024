<template>
  <div class="bg-white rounded-xl">
    <TsRow append-class="m-0 p-4 bg-natural-soft-white">
      <TsColumn append-class="p-0 is-2 md:is-1 flex items-center ms-2">
        <NuxtImg
          v-if="
            trolleyStore.previewed_product_v2 &&
            trolleyStore.previewed_product_v2.image
          "
          class="rounded-lg"
          :src="trolleyStore.previewed_product_v2?.image"
          alt="product"
          width="49"
          height="49"
          loading="lazy"
        />
      </TsColumn>
      <TsColumn append-class="p-0 is-9 md:is-10">
        <TsTypography append-class="text-idle-black leading-6">
          Variants of
        </TsTypography>
        <TsRow append-class="m-0 pr-7">
          <TsTypography v-if="trolleyStore.previewed_product_v2?.full_name" append-class="mb-0 text-idle-black leading-5">
            <span class="font-bold">{{
              trolleyStore.previewed_product_v2?.full_name.split(" ")[0]
            }}</span>
            {{
              trolleyStore.previewed_product_v2?.full_name
                ?.split(" ")
                .slice(1)
                .join(" ")
            }}
          </TsTypography>
          <TsTypography v-else append-class="mb-0 text-idle-black">
            Lorem ipsum dolor sit amet consectetur adipisicing
          </TsTypography>
        </TsRow>
      </TsColumn>
      <TsColumn append-class="p-0 is-1">
        <TsIcon
          name="bitcoin-icons:cross-filled"
          @click="emit('close')"
          class="absolute top-3 right-3 text-idle-black hover:bg-info/10 rounded transition-colors cursor-pointer"
        />  
      </TsColumn>
    </TsRow>

    <div class="overflow-y-auto max-h-[28rem] h-full relative bg-natural-soft-white">
      <!-- variant listing -->
      <template v-if="trolleyStore.delivery_product_variants.length">
        <TsCard
          v-for="variant in trolleyStore.delivery_product_variants"
          :key="variant.code"
          append-class="bg-white m-4 p-3"
          no-shadow
          no-border
        >
          <TsRow append-class="justify-between m-0">
            <TsColumn append-class="p-0">
              <TsTypography
                as="h2"
                append-class="text-idle-black leading-6 mb-1"
                >{{ variant.pack_size }}
                <small class="text-natural-grey"> ({{ variant.code }}) </small>
              </TsTypography>
            </TsColumn>
            <TsColumn append-class="p-0 grow-0">
              <TsTag
                v-if="variant.stockDetails?.delivery && variant.quantity <= variant.stockDetails.delivery"
                :label="`${variant.stockDetails?.delivery!} available`"
                variant="info"
                rounded
                append-class="py-1"
                size="sm"
              />
              <TsTag
                v-else
                label="Out of stock"
                variant="danger"
                rounded
                append-class="py-1"
                size="sm"
              />
            </TsColumn>
          </TsRow>
          <TsRow append-class="m-0 justify-between items-center">
            <TsColumn class="p-0">
              <TsRow
                v-if="variant.prices?.was_price_raw ?? 0 > 0"
                append-class="m-0 gap-1"
              >
              <div :class="`variant.prices?.raw.gross < variant.prices.was_price_raw && h-4`">
                <TsTypography
                  size="sm"
                  append-class="line-through leading-4 m-0"
                  v-if="(variant?.prices && variant?.prices?.raw && variant?.prices?.was_price_formatted)
                  && variant?.prices?.raw.gross < variant?.prices.was_price_raw"
                >
                  {{ variant.prices?.was_price_formatted }}
                </TsTypography>
              </div>
                <TsTypography
                  size="sm"
                  weight="semibold"
                  append-class="text-success leading-4"
                >
                  ({{ variant.prices?.formatted?.discount_percentage }} OFF)
                </TsTypography>
              </TsRow>
              <TsTypography
                variant="h2"
                weight="bold"
                append-class="text-idle-black leading-normal mb-0 leading-6"
              >
                {{
                  rootStore.isIncVat
                    ? variant.prices?.formatted.gross
                    : variant.prices?.formatted.net
                }}
              </TsTypography>
              <TsTypography variant="xs" append-class="leading-4 text-natural-grey">
                {{ rootStore.isIncVat ? "Excl." : "Inc." }} VAT
                {{
                  rootStore.isIncVat
                    ? variant.prices?.formatted.net
                    : variant.prices?.formatted.gross
                }}
              </TsTypography>
            </TsColumn>
            <TsColumn class="p-0 mr-4 max-w-max">
              <TsQuantityCounter
                append-class="w-full"
                data-testid="delivery-variant-quantity"
                v-model="variant.quantity"
                :minimumAllowableQuantity="0"
              />
            </TsColumn>
          </TsRow>
          <TsRow
            v-if="
              variant.quantity > 0 &&
              variant.quantity <= variant.stockDetails?.delivery!
            "
            append-class="py-0.5 px-2 mt-2 rounded-none font-medium text-success border-s-2 border-success text-sm bg-success/5"
          >
            Delivery
          </TsRow>
          <TsRow
            v-if="variant.quantity > variant.stockDetails?.delivery!"
            append-class="py-2"
          >
            <TsButton
              v-if="isSubscribedToStock(variant)"
              icon="material-symbols:unsubscribe-outline"
              :label="unsubscribeLabel"
              :is-loading="isStockUnsubscribeCTAloading"
              @click="handleStockUnsubscribe(variant.code)"
              append-class="mt-2"
              outlined
              block
            />
            <TsButton
              v-else
              icon="mdi:bell-outline"
              :label="notifyMeLabel"
              @click="emit('notify', variant)"
              append-class="mt-2"
              block
            />
          </TsRow>
        </TsCard>
      </template>
      <!-- variant loader -->
      <template v-else>
        <TsCard
          v-for="n in 2"
          :key="n"
          data-testid="product-variant-preview"
          append-class="p-3 bg-idle-white animate-pulse"
          no-shadow
          no-border
        >
          <div class="flex justify-between items-center mb-1">
            <div class="h-3 bg-blue-300/70 rounded-lg w-48"></div>
            <div class="h-3 bg-blue-300/70 rounded-lg w-28"></div>
          </div>
          <div class="h-3 bg-blue-300/70 rounded-lg w-36 mb-1"></div>
          <div class="h-2 bg-blue-300/70 rounded-lg w-20 mt-2"></div>
          <div class="mt-2 h-10 bg-blue-300/70 rounded-lg mx-10"></div>
        </TsCard>
      </template>
    </div>

    <TsColumn append-class="px-4 sticky bottom-0 z-10 bg-white md:rounded-b-xl">
      <TsButton
        v-bind="$attrs"
        :disabled="!variantsToAdd.length || props.disabled"
        block
        icon="mdi:trolley-outline"
        size="sm"
        append-class="max-w-100 my-2"
        @click="emit('addAllToTrolley', variantsToAdd)"
      />
    </TsColumn>
  </div>
</template>

<script setup lang="ts">
import type { TrolleyLineRequest } from "~/types/ecom/trolley/trolley.type";

const trolleyStore = useTrolleyStore();
const rootStore = useRootStore();

type Props = {
  disabled?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
});

const emit = defineEmits<{
  close: any;
  notify: [variant: TrolleyItemVariant];
  addAllToTrolley: [variants: TrolleyLineRequest[]];
}>();

defineOptions({
  inheritAttrs: false,
});

// OOS notifications
const isStockUnsubscribeCTAloading = ref(false);

const unsubscribeLabel = useTranslation("unsubscribe", "Uitschrijven");
const notifyMeLabel = useTranslation("notifyMe", "Breng mij op de hoogte");

function isSubscribedToStock(variant: TrolleyItemVariant) {
  const user: any = useAuthStore().user;
  if (!user || !user.id) return false;

  return useAccountStore().stock_notifications.some(
    (notification: any) => notification.product_id === variant.code
  );
}

async function handleStockUnsubscribe(productCode: string) {
  isStockUnsubscribeCTAloading.value = true;
  await useAuthStore().onStockUnsubscribe(productCode);
  isStockUnsubscribeCTAloading.value = false;
}

// pre-computation
const variantsToAdd = computed<TrolleyLineRequest[]>(() =>
  trolleyStore.delivery_product_variants
    .filter(
      (variant) =>
        variant.quantity &&
        variant.quantity > 0 &&
        variant.quantity <= variant.stockDetails?.delivery!
    )
    .map((variant) => ({
      product_code: variant.code,
      quantity: variant.quantity,
    }))
);
</script>